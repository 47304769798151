import { http } from '@/plugins/axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { router } from '@/router'
import { setCookie } from '@/utils/support'
import { asyncRoutes, constantRoutes, anyRoutes } from '@/router/routes'

const computeAsyncRoutes = (oriAsyncRoutes, authMenus = []) => {
  const res = oriAsyncRoutes.filter(item => {
    if (authMenus.indexOf(item.name) != -1) {
      if (item.children && item.children.length > 0) {
        item.children = computeAsyncRoutes(item.children, authMenus)
      }
      return true
    }
  })
  return res
}

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    user: {},
    isAdmin: 0,
    authMenus: [],
    authBtns: [],
    resultAsyncRoutes: [], // 对比后的最终路由
    resultAllRoutes: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AUTH_MENU: (state, authMenu) => {
      state.authMenus = authMenu
      state.authBtns = authMenu
    },
    SET_IS_ADMIN: (state, isAdmin) => {
      state.isAdmin = isAdmin
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USER_INFO: (state, userInfo) => {
      state.name = userInfo
    },
    SET_RESULT_ASYNC_ROUTES: (state, asyncRoutes) => {
      state.resultAsyncRoutes = asyncRoutes
      let routes = constantRoutes.concat(asyncRoutes, anyRoutes)
      routes.forEach(item => {
        if (item.children && item.children.length > 0) {
          for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].meta && !item.children[i].meta.hidden) {
              item.redirect = item.children[i].path
              break
            }
          }
        }
      })

      router.addRoutes(routes)
    }
  },

  getters: {
    getUserInfo: state => state.user,

    authBtns: state => state.authBtns,

    isAdmin: state => state.isAdmin,

    getResultRoutes: state => {
      const calAsyncRoutes = state.isAdmin ? asyncRoutes() : computeAsyncRoutes(asyncRoutes(), state.authMenus)
      let res = constantRoutes.concat(calAsyncRoutes, anyRoutes)

      res.forEach(item => {
        if (item.children && item.children.length > 0) {
          for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].meta && !item.children[i].meta.hidden) {
              item.redirect = item.children[i].path
              break
            }
          }
        }
      })

      router.addRoutes(res)
      return res
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.mobile.trim()
      return new Promise((resolve, reject) => {
        http({
          url: '/capital/auth/login',
          method: 'POST',
          data: {
            mobile: username,
            password: userInfo.password,
            rcKey: userInfo.rcKey,
            code: userInfo.code
          }
        })
          .then(response => {
            const tokenStr = response.token
            // let authMenus = response.routeCodeList || []
            // let isAdmin = response.isSuper || 0
            setToken(tokenStr)
            commit('SET_TOKEN', tokenStr)
            commit('SET_IS_ADMIN', 1)
            // commit('SET_AUTH_MENU', authMenus)
            commit('SET_USER_INFO', response.capitalName)
            // commit('SET_RESULT_ASYNC_ROUTES', isAdmin ? asyncRoutes() : computeAsyncRoutes(asyncRoutes(), authMenus))
            // localStorage.setItem('userName', username)
            resolve()
          })
          .catch(error => {
            console.log('error-----', error)
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(response => {
            const data = response.data
            if (data.roles && data.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit('SET_ROLES', data.roles)
            } else {
              reject('getInfo: roles must be a non-null array !')
            }
            commit('SET_NAME', data.username)
            commit('SET_AVATAR', data.icon)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        http({
          url: '/channel/auth/logout',
          method: 'POST'
        })
          .then(() => {
            dispatch('tagsView/delAllViews')
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_RESULT_ASYNC_ROUTES', [])
            removeToken()

            resolve()
          })
          .catch(error => {
            console.log(222)
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
